import { createItemsStore } from '@/utils/pinia/index.js'
import { useApiClient } from '@/composables/api-client'

export const useFantasyMemberCardsStore = createItemsStore(
  'fantasy-member-cards',
  {
    path: '/api/v1/fantasy/member/cards',
    query: {
      expand: ['currentCard.player.team', 'currentCard.labels']
    },
    extends: {
      actions: {
        async upgradeCard(id) {
          const apiClient = useApiClient()
          const data = await apiClient.post(
            `/api/v1/fantasy/member/cards/${id}/upgrade?expand=currentCard.player.team,currentCard.labels`
          )
          this.updateItem(id, data)
          return data
        },
        async sellCard(id) {
          const apiClient = useApiClient()
          const data = await apiClient.delete(
            `/api/v1/fantasy/member/cards/${id}/destroy`
          )
          this.removeItem(id)
          return data
        }
      }
    }
  }
)
